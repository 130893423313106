/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { selectEmpty, showToast } from "utils/common";
import { forwardRef } from "react";
import MenuService from "services/menus";
import {
  MENU_STATUS,
  MENU_TYPE,
  MENU_TYPE_VIEW,
  MENU_POSITION,
} from "utils/enum";
import { Calendar, Dropdown, InputNumber } from "primereact";
import VUpload from "components/v-upload";
import { TreeSelect } from "primereact/treeselect";
import { InputSwitch } from "primereact/inputswitch";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    treeMenus,
    loadTreeMenus,
  } = props;

  const emptyData = {
    name: "",
    key: "",
    type: MENU_TYPE.MENU,
    type_view: MENU_TYPE_VIEW.INTERNAL,
    url: "",
    icon: "",
    img_icon: "",
    parent_id: null,
    category: "",
    order: 0,
    status: MENU_STATUS.ENABLE,
    module: "",
    is_coming_soon: false,
    is_open_menu: false,
    is_pdf: false,
    is_vr360: false,
    pdf_file: "",
    coming_soon: {
      url: "",
      name: "",
      title: "",
      countdown: "",
      icon: null,
      background: null,
    },
    position: MENU_POSITION.MENU_DASHBOARD,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name: string, value: any, field?: string) => {
    if (field) {
      setDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [name]: value,
        },
      }));
    } else {
      setDetails((prev) => ({
        ...prev,
        [`${name}`]: value === "-1" ? null : value,
      }));
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await MenuService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await MenuService.create({
          body: { ...details },
        });
      setLoading(false);
      loadTreeMenus();
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="name">Position</label>
        <InputText
          id="position"
          value={details.position}
          onChange={(e) => onChange("position", e.target.value)}
          required
        />
      </div> */}

      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key (Language)</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>

      {/* <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(MENU_TYPE).map((k) => ({
            label: k,
            value: MENU_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type", e.value)}
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Position</label>
        <Dropdown
          filter
          value={details.position}
          options={Object.keys(MENU_POSITION).map((k) => ({
            label: k,
            value: MENU_POSITION[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("position", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type View</label>
        <Dropdown
          value={details.type_view}
          options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
            label: k,
            value: MENU_TYPE_VIEW[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type_view", e.value)}
        />
      </div>
      <div className="field col-12">
        <div className="mt-2">
          <VUpload
            label="Upload Icon"
            urlFile={details.img_icon}
            setUrlFile={(file) => onChange("img_icon", file)}
          />
        </div>
      </div>
      <div className="field col-6">
        <label>Is Coming Soon</label>
        <br />
        <InputSwitch
          checked={details.is_coming_soon}
          onChange={(e) => onChange("is_coming_soon", e.target.value)}
        />
      </div>
      <div className="field col-6">
        <label>Is Open Menu</label>
        <br />
        <InputSwitch
          checked={details.is_open_menu}
          onChange={(e) => onChange("is_open_menu", e.target.value)}
        />
      </div>
      {details.is_coming_soon && (
        <>
          <div className="field col-12 md:col-4">
            <label htmlFor="url">Url</label>
            <InputText
              id="url"
              value={details.coming_soon?.url}
              onChange={(e) => onChange("url", e.target.value, "coming_soon")}
              required
            />
          </div>
          {/* <div className="field col-12 md:col-3">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.coming_soon?.name}
              onChange={(e) => onChange("name", e.target.value, "coming_soon")}
              required
            />
          </div> */}
          <div className="field col-12 md:col-4">
            <label htmlFor="title">Title</label>
            <InputText
              id="title"
              value={details.coming_soon?.title}
              onChange={(e) => onChange("title", e.target.value, "coming_soon")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="countdown">Countdown</label>
            <span className="p-float-label">
              <Calendar
                className="w-full"
                id="time24"
                value={
                  !!details.coming_soon?.countdown
                    ? new Date(details.coming_soon?.countdown)
                    : undefined
                }
                dateFormat="mm-dd-yy"
                onChange={(e: any) =>
                  onChange("countdown", e.value, "coming_soon")
                }
                showTime
                showSeconds
                showButtonBar
              />
            </span>
          </div>
          <div className="field col-12 md:col-6">
            <span className="p-float-label">
              <VUpload
                label="Icon"
                urlFile={details.coming_soon?.icon}
                setUrlFile={(file) => {
                  onChange("icon", file, "coming_soon");
                }}
                isShowInit={false}
              />
            </span>
          </div>
          <div className="field col-12 md:col-6">
            <span className="p-float-label">
              <VUpload
                label="Background"
                urlFile={details.coming_soon?.background}
                setUrlFile={(file) => {
                  onChange("background", file, "coming_soon");
                }}
                isShowInit={false}
              />
            </span>
          </div>
        </>
      )}
      <div className="field col-12">
        <label>Is PDF</label>
        <br />
        <InputSwitch
          checked={details.is_pdf}
          onChange={(e) => onChange("is_pdf", e.target.value)}
        />
      </div>
      {details.is_pdf && (
        <div className="field col-12 md:col-6">
          <span className="p-float-label">
            <VUpload
              label="PDF file"
              fileType=".pdf"
              urlFile={details.pdf_file}
              setUrlFile={(file) => {
                onChange("pdf_file", file);
              }}
              isShowInit={false}
            />
          </span>
        </div>
      )}

      <div className="field col-6">
        <label>Is VR360</label>
        <br />
        <InputSwitch
          checked={details.is_vr360}
          onChange={(e) => onChange("is_vr360", e.target.value)}
        />
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="parent">Parent</label>
        <TreeSelect
          inputId="treeselect"
          filter
          selectionMode="single"
          placeholder="Select Parent Menu"
          value={details.parent_id}
          onChange={(e) => onChange("parent_id", e.value)}
          options={[selectEmpty, ...treeMenus]}
        ></TreeSelect>
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">URL</label>
        <InputText
          id="url"
          value={details.url}
          onChange={(e) => onChange("url", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Module</label>
        <InputText
          id="module"
          value={details.module}
          onChange={(e) => onChange("module", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(MENU_STATUS).map((k) => ({
            label: k,
            value: MENU_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
