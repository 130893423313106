import React from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";

export const AppTopbar = (props: any) => {
  const history = useHistory();
  const { setAuth } = useContext(MasterContext);

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem("token_auth");
    history.push("/login");
  };

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src={"/assets/layout/images/logo-dark.png"} alt="logo" />
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-calendar" />
            <span>Events</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-cog" />
            <span>Settings</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-user" />
            <span>Profile</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={handleLogout}
          >
            <i className="pi pi-sign-out"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
