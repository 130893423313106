import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useEffect, useState } from "react";
import UserService from "services/users";
import { formatNumber } from "utils/common";

const ModalWallet = (props) => {
  const { user } = props;

  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (user) {
      getBalanceByUserId();
    }
  }, [user]);

  const getBalanceByUserId = async () => {
    try {
      const res: any = await UserService.getBalanceByUserId({
        params: {
          userId: user._id,
        },
      });
      if (res) {
        const formattedDetails = Object.entries(res.balances)
          .filter(([key]) => key !== "rate")
          .map(([key, value]) => ({ key, value }));
        setDetails(formattedDetails);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Card header={<h5>User: {user.email}</h5>}>
        <DataTable
          value={details}
          responsiveLayout="scroll"
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          showGridlines
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="key"
            header="Name"
            body={(rowData) => <span>{rowData.key}</span>}
          ></Column>

          <Column
            field="value"
            header="Quantity"
            body={(rowData) => <span>{formatNumber(rowData.value)}</span>}
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default forwardRef(ModalWallet);
