import React from "react";

export const AppFooter = (props: any) => {
  return (
    <div className="layout-footer">
      <img
        src={"/assets/layout/images/logo-dark.png"}
        alt="Logo"
        height="20"
        className="mr-2"
      />
      by
      <span className="font-medium ml-2"> Vzonex Team</span>
    </div>
  );
};
